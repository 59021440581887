import React, { useState } from "react";
import { getLocalData } from "../../utils/functions";
import CoachCalendly from "../../modals/coach-calendly";
import { useSelector } from "react-redux";
import TestimonialModal from "../../modals/testimonial-modal";
import ReferFriend from "../../modals/refer-friend-modal";
import CoachingLinksModal from "../../modals/coachingLinksModal";

const Sidebar = ({
  loginUser,
  view,
  setView,
  showSidebar,
  setShowSidebar,
  pathName,
  history,
}) => {
  const userType = getLocalData("userType");
  const [testimonial, setTestimonial] = useState(false);
  const [referFriend, setReferFriend] = useState(false);
  const [coachingLinksModal, setCoachingLinksModal] = useState(false);
  const [modalType, setModalType] = useState("");

  const [coachCalendly, setCoachCalendly] = useState(false);
  const [coachProfile, setCoachProfile] = useState(false);

  const { referralData, coachingLinks } = useSelector(
    (state) => state.dashboard,
  );

  const addDefaultSrc = (ev) => {
    ev.target.src =
      "https://dashboard.alulawellness.com/breakthrough-uploads/user-default.png";
  };

  return (
    <aside
      id="layout-menu"
      className={`layout-menu menu-vertical menu bg-menu-theme ${
        showSidebar ? "show-sidebar" : ""
      } `}
      style={{
        paddingLeft: window.innerWidth > 1600 ? 20 : 10,
      }}>
      <div
        className="app-brand demo py-lg-2 border-bottom mb-3 px-0"
        style={{ backgroundColor: "", marginTop: 0, paddingTop: 0 }}>
        <a
          href="https://alulawellness.com/"
          target="_blank">
          <img
            src="assets/img/logo.png"
            className="img-fluid"
            style={{ width: 220, backgroundColor: "" }}
          />
        </a>
      </div>
      {userType !== "admin" &&
        userType !== "coach" &&
        loginUser?.coach &&
        loginUser?.isClient && (
          <div className="coach-block text-center mb-4">
            <div className="avatar avatar-lg mb-3">
              <img
                src={
                  loginUser?.coach?.profilePic
                    ? loginUser?.coach?.profilePic
                    : "https://dashboard.alulawellness.com/breakthrough-uploads/user-default.png"
                }
                onError={addDefaultSrc}
                alt
                className="rounded-circle"
              />
            </div>
            <h4 className="text-uppercase fw-semi mb-1 text-dark">
              {loginUser?.coach?.name}
            </h4>
            <p className="text-uppercase text-body">your coach </p>

            <ul className="coach-btn list-unstyled">
              <li className="tooltip-side">
                <span className="tooltiptext">View Coach Information</span>
                <a
                  href={`${
                    loginUser?.coach?.profile_link ||
                    "https://alulawellness.com"
                  }?mo_jwt_token=${getLocalData("mo_jwt_token")}`}
                  target="_blank"
                  className="btn-circle gradient1">
                  <img src="assets/img/btn-user.svg" />
                </a>
              </li>
              <li className="tooltip-side">
                <span className="tooltiptext">
                  Schedule Consultation with Coach
                </span>
                <a
                  role="button"
                  onClick={() => {
                    setCoachCalendly(true);
                  }}
                  className="btn-circle gradient2">
                  <img src="assets/img/btn-calendar.svg" />
                </a>
              </li>
              <li className="tooltip-side">
                <span className="tooltiptext">Group Coaching Sessions</span>
                <a
                  role="button"
                  onClick={() => {
                    setCoachingLinksModal(true);
                  }}
                  className="btn-circle gradient3">
                  <img
                    style={{ height: 25 }}
                    src="assets/img/group-coaching-icon.png"
                  />
                </a>
              </li>
            </ul>
          </div>
        )}

      {loginUser?.isClient === false &&
        userType !== "admin" &&
        userType !== "coach" && (
          <div className="coach-block text-center mb-4">
            <p className="text-uppercase text-body">
              Coach details will be displayed here
            </p>
          </div>
        )}

      {userType !== "admin" && userType !== "coach" && loginUser?.isClient && (
        <>
          {!loginUser?.coach && (
            <div style={{ marginTop: 40, marginBottom: 30 }}>
              <h5
                style={{
                  color: "#333",
                  textAlign: "center",
                  fontSize: 24,
                  lineHeight: 1.2,
                  fontWeight: 600,
                }}>
                Group Coaching Sessions
              </h5>
              {coachingLinks?.map((val, i) => (
                <a
                  key={i}
                  style={{ fontWeight: 700, fontSize: 15, display: "block" }}
                  target="_blank"
                  href={val?.url}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M3 3.993C3 3.445 3.445 3 3.993 3h16.014c.548 0 .993.445.993.993v16.014a.994.994 0 0 1-.993.993H3.993A.993.993 0 0 1 3 20.007V3.993Zm7.622 4.422a.4.4 0 0 0-.622.332v6.506a.4.4 0 0 0 .622.332l4.879-3.252a.401.401 0 0 0 0-.666l-4.88-3.252Z"
                    />
                  </svg>{" "}
                  {val?.text}
                </a>
              ))}
            </div>
          )}
        </>
      )}

      <div id="menu-scroll">
        <div className="menu-block">
          <ul className="menu-inner">
            {(userType === "admin" || userType === "coach") && (
              <li
                className={`menu-item ${
                  pathName === "/dashboard" ? "active" : ""
                }`}>
                <a
                  role="button"
                  className="menu-link"
                  onClick={() => {
                    history.push("/dashboard");
                    window.scrollTo(0, 0);
                    setShowSidebar(false);
                  }}>
                  <svg
                    style={{ marginRight: 10 }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20">
                    <path
                      fill="currentColor"
                      d="M20 12a2 2 0 0 0-.703.133l-2.398-1.963c.059-.214.101-.436.101-.67C17 8.114 15.886 7 14.5 7S12 8.114 12 9.5c0 .396.1.765.262 1.097l-2.909 3.438A2.06 2.06 0 0 0 9 14c-.179 0-.348.03-.512.074l-2.563-2.563C5.97 11.348 6 11.179 6 11c0-1.108-.892-2-2-2s-2 .892-2 2s.892 2 2 2c.179 0 .348-.03.512-.074l2.563 2.563A1.906 1.906 0 0 0 7 16c0 1.108.892 2 2 2s2-.892 2-2c0-.237-.048-.46-.123-.671l2.913-3.442c.227.066.462.113.71.113a2.48 2.48 0 0 0 1.133-.281l2.399 1.963A2.077 2.077 0 0 0 18 14c0 1.108.892 2 2 2s2-.892 2-2s-.892-2-2-2z"
                    />
                  </svg>
                  <div>Global stats</div>
                </a>
              </li>
            )}
            <li
              className={`menu-item ${
                (pathName === "/dashboard" &&
                  userType !== "admin" &&
                  userType !== "coach") ||
                pathName === "/members"
                  ? "active"
                  : ""
              }`}>
              <a
                role="button"
                className="menu-link"
                onClick={() => {
                  userType === "admin" || userType === "coach"
                    ? history.push("/members")
                    : history.push("/dashboard");
                  window.scrollTo(0, 0);
                  setShowSidebar(false);
                }}>
                {userType === "admin" || userType === "coach" ? (
                  <svg
                    style={{ marginRight: 10 }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 16 16">
                    <g
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5">
                      <circle
                        cx="5"
                        cy="9"
                        r="2.25"
                      />
                      <circle
                        cx="11"
                        cy="4"
                        r="2.25"
                      />
                      <path d="M7.75 9.25c0-1 .75-3 3.25-3s3.25 2 3.25 3m-12.5 5c0-1 .75-3 3.25-3s3.25 2 3.25 3" />
                    </g>
                  </svg>
                ) : (
                  <i className="menu-icon tf-icons bx bxs-dashboard"></i>
                )}
                <div>
                  {userType === "admin" || userType === "coach"
                    ? " Members"
                    : " Dashboard"}{" "}
                </div>
              </a>
            </li>
            {userType === "admin" && (
              <li
                className={`menu-item ${
                  pathName === "/coaches" ? "active" : ""
                }`}>
                <a
                  role="button"
                  className="menu-link"
                  onClick={() => {
                    history.push("/coaches");
                    window.scrollTo(0, 0);
                    setShowSidebar(false);
                  }}>
                  <svg
                    style={{ marginRight: 10 }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 16 16">
                    <g
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5">
                      <circle
                        cx="5"
                        cy="9"
                        r="2.25"
                      />
                      <circle
                        cx="11"
                        cy="4"
                        r="2.25"
                      />
                      <path d="M7.75 9.25c0-1 .75-3 3.25-3s3.25 2 3.25 3m-12.5 5c0-1 .75-3 3.25-3s3.25 2 3.25 3" />
                    </g>
                  </svg>
                  <div>Coaches</div>
                </a>
              </li>
            )}
            {(userType === "admin" || userType === "coach") && (
              <li
                className={`menu-item ${
                  pathName === "/kudos-board" ? "active" : ""
                }`}>
                <a
                  role="button"
                  className="menu-link"
                  onClick={() => {
                    history.push("/kudos-board");
                    window.scrollTo(0, 0);
                    setShowSidebar(false);
                  }}>
                  <i className="menu-icon tf-icons bx bx-category"></i>
                  <div>Kudos Board</div>
                </a>
              </li>
            )}
            {userType !== "admin" && userType !== "coach" && (
              <>
                <li
                  className={`menu-item ${
                    pathName === "/journey" ? "active" : ""
                  }`}>
                  <a
                    role="button"
                    className="menu-link"
                    onClick={() => {
                      history.push("/journey");
                      window.scrollTo(0, 0);
                      setShowSidebar(false);
                    }}>
                    {/* <i className="menu-icon tf-icons bx bx-category"></i> */}
                    <i className="menu-icon tf-icons bx bxs-quote-alt-left"></i>
                    <div>My Journey</div>
                  </a>
                </li>
                {loginUser?.completedDays > 0 && (
                  <>
                    <li
                      className={`menu-item ${
                        pathName === "/daily-stats" ? "active" : ""
                      }`}>
                      <a
                        role="button"
                        className="menu-link"
                        onClick={() => {
                          history.push("/daily-stats");
                          window.scrollTo(0, 0);
                          setShowSidebar(false);
                        }}>
                        <i className="menu-icon tf-icons bx bxs-pie-chart-alt-2"></i>
                        <div>Daily Stats</div>
                      </a>
                    </li>
                    <li
                      className={`menu-item ${
                        pathName === "/weekly-photos" ? "active" : ""
                      }`}>
                      <a
                        role="button"
                        className="menu-link"
                        onClick={() => {
                          history.push("/weekly-photos");
                          window.scrollTo(0, 0);
                          setShowSidebar(false);
                        }}>
                        <i className="menu-icon tf-icons bx bxs-image"></i>
                        <div>Weekly Photos</div>
                      </a>
                    </li>
                  </>
                )}
                <li
                  className={`menu-item ${
                    pathName === "/kudos-board" ? "active" : ""
                  }`}>
                  <a
                    role="button"
                    className="menu-link"
                    onClick={() => {
                      history.push("/kudos-board");
                      window.scrollTo(0, 0);
                      setShowSidebar(false);
                    }}>
                    <i className="menu-icon tf-icons bx bx-category"></i>
                    <div>Kudos Board</div>
                  </a>
                </li>

                <li
                  className={`menu-item ${
                    pathName === "/recipes" ? "active" : ""
                  }`}>
                  <a
                    role="button"
                    className="menu-link"
                    onClick={() => {
                      history.push("/recipes");
                      window.scrollTo(0, 0);
                      setShowSidebar(false);
                    }}>
                    <i className="menu-icon tf-icons bx bxs-book-alt"></i>
                    <div>Recipes</div>
                  </a>
                </li>
                <li
                  className={`menu-item ${
                    pathName === "/referral" ? "active" : ""
                  }`}>
                  <a
                    role="button"
                    className="menu-link"
                    onClick={() => {
                      history.push("/referral");
                      window.scrollTo(0, 0);
                      setShowSidebar(false);
                    }}>
                    <svg
                      style={{ marginRight: 10 }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 16 16">
                      <g
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5">
                        <circle
                          cx="5"
                          cy="9"
                          r="2.25"
                        />
                        <circle
                          cx="11"
                          cy="4"
                          r="2.25"
                        />
                        <path d="M7.75 9.25c0-1 .75-3 3.25-3s3.25 2 3.25 3m-12.5 5c0-1 .75-3 3.25-3s3.25 2 3.25 3" />
                      </g>
                    </svg>
                    <div>Breakthrough Buck$</div>
                    {+referralData?.credit_amount > 0 && (
                      <div
                        style={{
                          fontWeight: 700,
                          position: "absolute",
                          right: 15,
                          top: -1,
                          color: "black",
                          backgroundColor: "#6fac49",
                          borderRadius: "50%",
                          height: 20,
                          width: 20,
                          textAlign: "center",
                        }}>
                        $
                      </div>
                    )}
                  </a>
                </li>
                <li
                  className={`menu-item ${
                    pathName === "/bonusVideos" ? "active" : ""
                  }`}>
                  <a
                    role="button"
                    className="menu-link"
                    onClick={() => {
                      history.push("/bonusVideos");
                      window.scrollTo(0, 0);
                      setShowSidebar(false);
                    }}>
                    <i
                      style={{ fontSize: 20, marginRight: 10 }}
                      className="bx bxs-video"></i>
                    <div>Bonus videos</div>
                  </a>
                </li>
                <li
                  className={`menu-item ${
                    pathName === "/profile" ? "active" : ""
                  }`}>
                  <a
                    role="button"
                    className="menu-link"
                    onClick={() => {
                      window.open(
                        `https://alulawellness.com/my-account?mo_jwt_token=${getLocalData(
                          "mo_jwt_token",
                        )}`,
                      );
                    }}>
                    <i
                      style={{ fontSize: 20, marginRight: 10 }}
                      className="bx bxs-user"></i>
                    <div>My Account</div>
                  </a>
                </li>
              </>
            )}

            {/* <li className="menu-item">
              <a role="button" className="menu-link menu-toggle">
                <i className="menu-icon tf-icons bx bx-cube-alt"></i>
                <div data-i18n="Misc">Misc</div>
              </a>
              <ul className="menu-sub">
                <li className="menu-item">
                  <a href="" className="menu-link">
                    <div data-i18n="Error">Error</div>
                  </a>
                </li>
              </ul>
            </li> */}
          </ul>
        </div>

        <div className="menu-block quick-links">
          <h6 className="semi my-2 ps-3 fs-14 text-body">Quick Links</h6>
          <ul className="menu-inner">       

              <li className="menu-item">
                <a                 
                  href={`https://alulawellness.com/shop?mo_jwt_token=${getLocalData(
                    "mo_jwt_token",
                  )}`}
                  target="_blank"
                  className="menu-link fs-12">
                  {/* <i className="bx bxs-store"></i> */}
                  <i className="menu-icon tf-icons bx bxs-store"></i>

                  <div>Breakthrough M2 Store</div>
                </a>
              </li>

            {(loginUser?.isClient ||
              userType === "admin" ||
              userType === "coach") && (
              <li className="menu-item">
                <a
                  // style={{
                  //   pointerEvents:
                  //     loginUser?.completedDays === 0 &&
                  //     loginUser?.completed !== 1
                  //       ? "none"
                  //       : "",
                  // }}
                  href="https://www.flipsnack.com/9ED6BACC5A8/2023-btm2-full-manual.html"
                  target="_blank"
                  className="menu-link fs-12">
                  <svg
                    style={{ padding: 5 }}
                    className="menu-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M21 3h-7a2.98 2.98 0 0 0-2 .78A2.98 2.98 0 0 0 10 3H3a1 1 0 0 0-1 1v15a1 1 0 0 0 1 1h5.758c.526 0 1.042.214 1.414.586l1.121 1.121c.009.009.021.012.03.021c.086.079.182.149.294.196h.002a.996.996 0 0 0 .762 0h.002c.112-.047.208-.117.294-.196c.009-.009.021-.012.03-.021l1.121-1.121A2.015 2.015 0 0 1 15.242 20H21a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM8.758 18H4V5h6c.552 0 1 .449 1 1v12.689A4.032 4.032 0 0 0 8.758 18zM20 18h-4.758c-.799 0-1.584.246-2.242.689V6c0-.551.448-1 1-1h6v13z"
                    />
                  </svg>
                  <div>BTM2 Manual</div>
                </a>
              </li>
            )}
            {(loginUser?.isClient ||
              userType === "admin" ||
              userType === "coach") && (
              <li className="menu-item">
                <a
                  // style={{
                  //   pointerEvents:
                  //     loginUser?.completedDays === 0 &&
                  //     loginUser?.completed !== 1
                  //       ? "none"
                  //       : "",
                  // }}
                  href={`https://alulawellness.com/my-account?view=wiki&mo_jwt_token=${getLocalData(
                    "mo_jwt_token",
                  )}`}
                  target="_blank"
                  className="menu-link fs-12">
                  <svg
                    style={{ padding: 5 }}
                    className="menu-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="m12 16l4-5h-3V4h-2v7H8z"
                    />
                    <path
                      fill="currentColor"
                      d="M20 18H4v-7H2v7c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2v-7h-2v7z"
                    />
                  </svg>{" "}
                  <div>Downloadable Docs</div>
                </a>
              </li>
            )}

            {(loginUser?.isClient ||
              userType === "admin" ||
              userType === "coach") && (
              <li className="menu-item">
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    window.scrollTo(0, 0);
                    history.push("/photo-stamps");
                  }}
                  role="butotn"
                  className="menu-link fs-12">
                  <i className="menu-icon tf-icons bx bx-images"></i>
                  <div>Weekly Photo Stamps</div>
                </a>
              </li>
            )}

            <li className="menu-item">
              <a
                href={`https://alulawellness.com/faqs?mo_jwt_token=${getLocalData(
                  "mo_jwt_token",
                )}`}
                target="_blank"
                className="menu-link fs-12">
                <i className="menu-icon tf-icons bx bx-help-circle"></i>
                <div>View FAQ</div>
              </a>
            </li>

            <li className="menu-item">
              <a
                href="https://www.facebook.com/groups/572747266140767/"
                target="_blank"
                className="menu-link fs-12">
                <i className="menu-icon tf-icons bx bxl-facebook"></i>
                <div>Team Breakthrough M2</div>
              </a>
            </li>

            <li className="menu-item">
              <a
                href="https://www.facebook.com/BreakthroughM2Weightloss/"
                target="_blank"
                className="menu-link fs-12">
                <i className="menu-icon tf-icons bx bxl-facebook"></i>
                <div>Facebook</div>
              </a>
            </li>
            <li className="menu-item">
              <a
                href="https://www.instagram.com/breakthroughm2/"
                target="_blank"
                className="menu-link fs-12">
                <svg
                  style={{ padding: 5 }}
                  className="menu-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3Z"
                  />
                </svg>
                <div>Instagram</div>
              </a>
            </li>
            <li className="menu-item">
              <a
                href="https://www.youtube.com/channel/UCewcMbF3vZ_VYsP_26u91VQ"
                target="_blank"
                className="menu-link fs-12">
                <svg
                  style={{ padding: 5 }}
                  className="menu-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="m10 15l5.19-3L10 9v6m11.56-7.83c.13.47.22 1.1.28 1.9c.07.8.1 1.49.1 2.09L22 12c0 2.19-.16 3.8-.44 4.83c-.25.9-.83 1.48-1.73 1.73c-.47.13-1.33.22-2.65.28c-1.3.07-2.49.1-3.59.1L12 19c-4.19 0-6.8-.16-7.83-.44c-.9-.25-1.48-.83-1.73-1.73c-.13-.47-.22-1.1-.28-1.9c-.07-.8-.1-1.49-.1-2.09L2 12c0-2.19.16-3.8.44-4.83c.25-.9.83-1.48 1.73-1.73c.47-.13 1.33-.22 2.65-.28c1.3-.07 2.49-.1 3.59-.1L12 5c4.19 0 6.8.16 7.83.44c.9.25 1.48.83 1.73 1.73Z"
                  />
                </svg>
                <div>YouTube</div>
              </a>
            </li>

            <li className="menu-item">
              <a
                href="https://alulawellness.com/inspiring-blog/"
                target="_blank"
                className="menu-link fs-12">
                <i
                  className="menu-icon tf-icons bx bxs-book-alt"
                  style={{ fontSize: 12 }}></i>
                {/* <svg
                  style={{ padding: 5 }}
                  className="menu-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="m10 15l5.19-3L10 9v6m11.56-7.83c.13.47.22 1.1.28 1.9c.07.8.1 1.49.1 2.09L22 12c0 2.19-.16 3.8-.44 4.83c-.25.9-.83 1.48-1.73 1.73c-.47.13-1.33.22-2.65.28c-1.3.07-2.49.1-3.59.1L12 19c-4.19 0-6.8-.16-7.83-.44c-.9-.25-1.48-.83-1.73-1.73c-.13-.47-.22-1.1-.28-1.9c-.07-.8-.1-1.49-.1-2.09L2 12c0-2.19.16-3.8.44-4.83c.25-.9.83-1.48 1.73-1.73c.47-.13 1.33-.22 2.65-.28c1.3-.07 2.49-.1 3.59-.1L12 5c4.19 0 6.8.16 7.83.44c.9.25 1.48.83 1.73 1.73Z"
                  />
                </svg> */}
                <div>Breakthrough Blogs</div>
              </a>
            </li>

            {(loginUser?.isClient ||
              userType === "admin" ||
              userType === "coach") && (
              <li className="menu-item">
                <a
                  href={`https://alulawellness.com/bulletin-board?mo_jwt_token=${getLocalData(
                    "mo_jwt_token", 
                  )}`}
                  target="_blank"
                  className="menu-link fs-12">
                  <i className="menu-icon tf-icons bx bxs-heart fs-12"></i>
                  <div>Bulletin Board</div>
                </a>
              </li>
            )}

            <li className="menu-item">
              <a
                href="https://www.amazon.com/shop/breakthroughm2?ref_=cm_sw_r_cp_ud_aipsfshop_aipsfbreakthroughm2_2XAEQJ1CH4S3FNMYMJCB"
                target="_blank"
                className="menu-link fs-12">
                <svg
                  style={{ padding: 5 }}
                  className="menu-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M22 5c0-1.654-1.346-3-3-3H5C3.346 2 2 3.346 2 5v2.831c0 1.053.382 2.01 1 2.746V19c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-8.424c.618-.735 1-1.692 1-2.746V5zm-2 0v2.831c0 1.14-.849 2.112-1.891 2.167L18 10c-1.103 0-2-.897-2-2V4h3c.552 0 1 .449 1 1zM10 4h4v4c0 1.103-.897 2-2 2s-2-.897-2-2V4zM4 5c0-.551.448-1 1-1h3v4c0 1.103-.897 2-2 2l-.109-.003C4.849 9.943 4 8.971 4 7.831V5zm6 14v-3h4v3h-4zm6 0v-3c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v3H5v-7.131c.254.067.517.111.787.125A3.988 3.988 0 0 0 9 10.643c.733.832 1.807 1.357 3 1.357s2.267-.525 3-1.357a3.988 3.988 0 0 0 3.213 1.351c.271-.014.533-.058.787-.125V19h-3z"
                  />
                </svg>{" "}
                <div>Amazon Storefront</div>
              </a>
            </li>

            {(loginUser?.isClient ||
              userType === "admin" ||
              userType === "coach") &&
            <li className="menu-item">
              <a
                 href={`https://alulawellness.com/my-account/orders?mo_jwt_token=${getLocalData(
                  "mo_jwt_token", 
                )}`}
                target="_blank"
                className="menu-link fs-12">
                  
                  <i className="menu-icon tf-icons bx bxs-receipt fs-12"></i>
               
                <div>Orders/Tracking</div>
              </a>
            </li>
        }

            {(userType === "admin" || loginUser?.id == 10467) && (
              <li className="menu-item">
                <a
                  href={`https://alulawellness.com/wp-admin?mo_jwt_token=${getLocalData(
                    "mo_jwt_token",
                  )}`}
                  target="_blank"
                  className="menu-link fs-12">
                  <i className="menu-icon tf-icons bx bxl-wordpress"></i>
                  <div>WordPress</div>
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>

      <CoachCalendly
        modalIsOpen={coachCalendly}
        setIsOpen={setCoachCalendly}
        closeModal={() => setCoachCalendly(false)}
      />
      <TestimonialModal
        modalIsOpen={testimonial}
        setIsOpen={setTestimonial}
        closeModal={() => setTestimonial(false)}
      />
      <ReferFriend
        modalIsOpen={referFriend}
        setIsOpen={setReferFriend}
        modalType={modalType}
        closeModal={() => setReferFriend(false)}
      />
      <CoachingLinksModal
        modalIsOpen={coachingLinksModal}
        setIsOpen={setCoachingLinksModal}
        closeModal={() => setCoachingLinksModal(false)}
      />
    </aside>
  );
};

export default Sidebar;

import {
  USER_STATS,
  URL,
  WEEKLY_PHOTOS,
  DAILY_NOTES,
  POSTS,
  POSTS_REACT,
  POST_COMMENT,
  GET_MEMBERS,
  GET_MEMBER_INFO,
  GET_MEMBERS_ADMIN,
  SAVE_MEMBER_UPDATE,
  RECEIPE,
  DAILY_TITLES,
  GET_WEIGHTS,
  REMOVE_REACTION,
  UPDATE_PRIVACY,
  DELETE_IMG,
  TESTIMONIAL,
  GET_PROFILE,
  BASICS,
  ACTIONS,
  RECEIPE_SEARCH,
} from "../../apis/routes";
import ApiService from "../../services/ApiServices";
import { SET_DASHBOARD_DATA } from "../constants/dashboardConstants";
import { setUIKey } from "./uiAction";

export const setDashboardData = (data) => ({
  type: SET_DASHBOARD_DATA,
  payload: data,
});

export const getUserStats = () => async (dispatch) => {
  try {
    const res = await ApiService.get(`${URL}/${USER_STATS}`);

    if (res?.data) {
      dispatch(setDashboardData({ userStats: res.data }));
    }

    return Promise.resolve(res?.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getUserOldStats = () => async (dispatch) => {
  try {
    const res = await ApiService.get(`${URL}/user/old-stats`);

    if (res?.data) {
      dispatch(setDashboardData({ userOldStats: res?.data }));
    }

    return Promise.resolve(res?.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getUserStatsById = (id) => async (dispatch) => {
  try {
    const res = await ApiService.get(`${URL}/admin-user/stats/${id}`);

    return Promise.resolve(res?.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const getUserNotesById = (id) => async () => {
  try {
    const res = await ApiService.get(`${URL}/user/admin/get-notes/${id}`);

    return Promise.resolve(res?.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getCoachMembers = () => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));

    const res = await ApiService.get(`${URL}/${GET_MEMBERS}`);
    dispatch(setUIKey("isLoading", false));

    if (res?.data) {
      dispatch(setDashboardData({ coachMebers: res.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getAdminMembers = () => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));

    const res = await ApiService.get(`${URL}/${GET_MEMBERS_ADMIN}`);
    dispatch(setUIKey("isLoading", false));

    if (res?.data) {
      dispatch(setDashboardData({ coachMebers: res.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getMemberInfo = (id) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));

    const res = await ApiService.get(`${URL}/${GET_MEMBER_INFO}/${id}`);
    dispatch(setUIKey("isLoading", false));

    if (res?.data?.adminData) {
      const obj = {
        ...res?.data?.data,
        ...res?.data?.adminData,
        displayName: res?.data?.adminData?.name,
      };

      return Promise.resolve(obj);
    } else {
      const obj = {
        ...res?.data,
        displayName: res?.data?.name,
      };

      return Promise.resolve(obj);
    }
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const saveUserStats = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/${USER_STATS}`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deleteUserStats = (id) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.get(`${URL}/user/stats/delete/${id}`);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateUserStats = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/user/update-stats`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const saveCoachNotes = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/user/admin/coach-notes`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateCoachNotesAction = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(
      `${URL}/user/admin/update-coach-notes`,
      data,
    );

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deleteCoachNotesAction = (body) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(
      `${URL}/user/admin/delete-coach-notes`,
      body,
    );

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const saveNewRecipe = (data) => async () => {
  try {
    const res = await ApiService.post(`${URL}/add-recipe`, data);

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const addRating = (data) => async () => {
  try {
    const res = await ApiService.post(`${URL}/add-rating`, data);

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const addRecipeComment = (data) => async () => {
  try {
    const res = await ApiService.post(`${URL}/add-recipe-comment`, data);

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const referFriendHandler = (data) => async () => {
  try {
    const res = await ApiService.post(`${URL}/externalapi/refer-friend`, data);

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const sendRedeem = (data) => async () => {
  try {
    const res = await ApiService.post(`${URL}/externalapi/send-redeem`, data);

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const sendEmailTemplateAction = (data) => async () => {
  try {
    const res = await ApiService.post(
      `${URL}/externalapi/send-email-template`,
      data,
    );

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const successScream = (data) => async () => {
  try {
    const res = await ApiService.post(
      `${URL}/externalapi/success-scream`,
      data,
    );

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const affiliateCode = (data) => async () => {
  try {
    const res = await ApiService.post(
      `${URL}/externalapi/affiliate-code`,
      data,
    );

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const addTestimonial = (data) => async () => {
  try {
    const res = await ApiService.post(`${URL}/externalapi/testimonial`, data);

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateUserData = (data) => async () => {
  try {
    const res = await ApiService.post(`${URL}/${GET_PROFILE}`, data);

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const saveUserPlaneStartsOn = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/${SAVE_MEMBER_UPDATE}`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getWeeklyPhotos = () => async (dispatch) => {
  try {
    const res = await ApiService.get(`${URL}/${WEEKLY_PHOTOS}`);

    if (res?.data) {
      dispatch(setDashboardData({ weeklyPhotos: res.data.reverse() }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const saveWeeklyPhoto = (data) => async (dispatch) => {
  try {
    const res = await ApiService.postFormData(`${URL}/${WEEKLY_PHOTOS}`, data);
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const saveRecipePhoto = async (data) => {  
  try {
    const res = await ApiService.postFormData(`${URL}/user/recipePhotos`, data);
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const saveTestimonialPhoto = async (data) => {  
  try {
    const res = await ApiService.postFormData(
      `${URL}/user/testimonialPhotos`,
      data,
    );
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getDailyNotes = (day) => async (dispatch) => {
  try {
    const res = await ApiService.get(`${URL}/${DAILY_NOTES}/${day}`);

    if (res?.data) {
      dispatch(setDashboardData({ dailyNotes: res.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const getNotifications = (day) => async (dispatch) => {
  try {
    const res = await ApiService.get(`${URL}/notifications`);

    if (res?.data) {
      dispatch(setDashboardData({ notifications: res.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getDailyNotesDay = (day, week) => async (dispatch) => {
  try {
    const res = await ApiService.get(`${URL}/${DAILY_NOTES}/${day}/${week}`);

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const savePosts = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/${POSTS}`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const saveNotification = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/notifications`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getNotificationStatus = () => async (dispatch) => {
  try {
    const res = await ApiService.get(`${URL}/notification-status`);
    if (res?.data?.id) {
      dispatch(setDashboardData({ isReadAll: res.data?.is_read_all }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const addNotificationStatus = () => async () => {
  try {
    const res = await ApiService.get(`${URL}/add-notification-status`);

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateNotificationStatus = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/notification-update`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const saveTestimonial = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/${TESTIMONIAL}`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const saveBasicsInfo = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/${BASICS}`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const saveHabitsInfo = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/habits`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const saveCompletedActions = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/${ACTIONS}`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateCompletedActions = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/actions-update`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const updateBonusAction = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/update-bonus`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateBasicsInfo = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/update-basic`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateHabitsInfo = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/habits-update`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getPosts =
  ({ type, category, onlyMyPosts }) =>
  async (dispatch) => {
    try {
      const res = await ApiService.get(
        `${URL}/${POSTS}/${type}/${category}/${onlyMyPosts} `,
      );

      if (res?.data) {
        dispatch(setDashboardData({ posts: res.data }));
      }

      return Promise.resolve(res.data);
    } catch (error) {
      console.log(error);
      return error;
    }
  };

export const getTestimonial = (onlyMyPost) => async (dispatch) => {
  try {
    const res = await ApiService.get(`${URL}/${TESTIMONIAL}/${onlyMyPost}`);

    if (res?.data) {
      dispatch(setDashboardData({ testimonials: res.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getBasics = () => async (dispatch) => {
  try {
    const res = await ApiService.get(`${URL}/${BASICS}`);

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getHabits = () => async (dispatch) => {
  try {
    const res = await ApiService.get(`${URL}/habits`);

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getActions = (step) => async (dispatch) => {
  try {
    const res = await ApiService.get(`${URL}/${ACTIONS}`);

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getReceipe =
  (page = 1) =>
  async (dispatch) => {
    try {
      dispatch(setUIKey("isLoading", true));

      dispatch(
        setDashboardData({
          receipes: [],
        }),
      );

      const res = await ApiService.get(`${URL}/${RECEIPE}/${page}`);

      if (res?.status === 403) {
        localStorage.removeItem("token");
        localStorage.removeItem("userId");

        window.location.href = "/login";
      }

      if (res?.data?.data) {
        dispatch(
          setDashboardData({
            receipes: res?.data.data,
            totalRecipes: res?.data?.totalRecipes,
          }),
        );
      }
      dispatch(setUIKey("isLoading", false));

      return Promise.resolve(res.data);
    } catch (error) {
      console.log(error);
      return error;
    }
  };

export const getReceipeBySearch =
  (page, text = "") =>
  async (dispatch) => {
    try {
      dispatch(setUIKey("isLoading", true));

      dispatch(
        setDashboardData({
          receipes: [],
        }),
      );

      const res = await ApiService.get(
        `${URL}/${RECEIPE_SEARCH}/${page}/${text}`,
      );

      if (res?.data?.data) {
        dispatch(
          setDashboardData({
            receipes: res?.data.data,
            totalRecipes: res?.data?.totalRecipes,
          }),
        );
      } else {
        dispatch(
          setDashboardData({
            totalRecipes: 0,
          }),
        );
      }
      dispatch(setUIKey("isLoading", false));

      return Promise.resolve(res.data);
    } catch (error) {
      console.log(error);
      return error;
    }
  };

export const getReceipeByCategory =
  (id, page = 1) =>
  async (dispatch) => {
    try {
      dispatch(setUIKey("isLoading", true));

      dispatch(
        setDashboardData({
          receipes: [],
        }),
      );

      const res = await ApiService.get(
        `${URL}/recipe-search-category/${id}/${page}`,
      );

      if (res?.data?.data) {
        dispatch(
          setDashboardData({
            receipes: res?.data.data,
            totalRecipes: res?.data?.totalRecipes,
          }),
        );
      } else {
        dispatch(
          setDashboardData({
            totalRecipes: 0,
          }),
        );
      }
      dispatch(setUIKey("isLoading", false));

      return Promise.resolve(res.data);
    } catch (error) {
      console.log(error);
      return error;
    }
  };

export const getComments = (postId) => async (dispatch) => {
  try {
    const res = await ApiService.get(`${URL}/${POST_COMMENT}/${postId} `);

    if (res?.data) {
      dispatch(setDashboardData({ comments: res.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getDailyTitles = () => async (dispatch) => {
  try {
    const res = await ApiService.get(`${URL}/${DAILY_TITLES} `);

    if (res?.data) {
      dispatch(setDashboardData({ journeyTitles: res.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getWeights = () => async (dispatch) => {
  try {
    const res = await ApiService.get(`${URL}/${GET_WEIGHTS} `);

    if (res?.data) {
      dispatch(setDashboardData({ userWeights: res.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getRatings = (id) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.get(`${URL}/get-ratings/${id}`);
    dispatch(setUIKey("isLoading", false));

    if (res?.data?.data) {
      dispatch(setDashboardData({ receipeRatings: res?.data?.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const getCategoryAction = () => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.get(`${URL}/get-category`);
    dispatch(setUIKey("isLoading", false));

    if (res?.data?.data) {
      dispatch(setDashboardData({ categories: res?.data?.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const getTagsAction = () => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.get(`${URL}/get-recipes-tag`);
    dispatch(setUIKey("isLoading", false));

    if (res?.data?.data) {
      dispatch(setDashboardData({ recipeTags: res?.data?.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getRecipeComments = (id) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.get(`${URL}/get-recipe-comments/${id}`);
    dispatch(setUIKey("isLoading", false));

    return Promise.resolve(res?.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deleteRecipeComment = (id) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.get(`${URL}/delete-recipe-comments/${id}`);
    dispatch(setUIKey("isLoading", false));

    return Promise.resolve(res?.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getAdminUser = (id) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.get(`${URL}/admin/get-user/${id}`);
    dispatch(setUIKey("isLoading", false));

    if (res?.data?.data) {
      dispatch(setDashboardData({ coachDetails: res?.data?.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getReferralData = () => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.get(`${URL}/admin/get-data`);
    dispatch(setUIKey("isLoading", false));

    if (res?.data?.data) {
      dispatch(setDashboardData({ referralData: res?.data?.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getUserCoupons = () => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.get(`${URL}/admin/get-user-coupons`);
    dispatch(setUIKey("isLoading", false));

    console.log('got coupon data', res)
    dispatch(setDashboardData({ userCoupons: res?.data?.data?.data?.coupons || [] }));

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getSignups = () => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.get(`${URL}/admin/get-signups`);
    dispatch(setUIKey("isLoading", false));

    if (res?.data?.data) {
      dispatch(setDashboardData({ signupsData: res?.data?.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getLinks = () => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.get(`${URL}/admin/get-links`);
    dispatch(setUIKey("isLoading", false));

    if (res?.data?.data?.links) {
      dispatch(setDashboardData({ coachingLinks: res?.data?.data?.links }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getVideos = () => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.get(`${URL}/admin/get-videos`);
    dispatch(setUIKey("isLoading", false));

    if (res?.data) {
      dispatch(setDashboardData({ bonusVideosData: res?.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getAdminCoach = (id) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.get(`${URL}/admin/get-coach/${id}`);
    dispatch(setUIKey("isLoading", false));

    if (res?.data?.data) {
      dispatch(setDashboardData({ coachDetails: res?.data?.data }));
    }

    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const postReact = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/${POSTS_REACT}`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const addComment = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/${POST_COMMENT}`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const removeReaction = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/${REMOVE_REACTION}`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updatePrivacy = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/${UPDATE_PRIVACY}`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const deletePostAction = (id) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.get(`${URL}/post/delete/${id}`);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const addResetDataAction = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/plan/reset-program`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deleteImangeAction = (data) => async (dispatch) => {
  try {
    dispatch(setUIKey("isLoading", true));
    const res = await ApiService.post(`${URL}/${DELETE_IMG}`, data);

    dispatch(setUIKey("isLoading", false));
    return Promise.resolve(res.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};
